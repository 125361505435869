import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { isOnServer } from '~/global/global.constants';
import { CraftspeopleRenderer } from '~/components/magic-tabccordion/Components/Renderers/MagicTabccordion-craftspeopleRenderer';
import { FAQRenderer } from '~/components/magic-tabccordion/Components/Renderers/MagicTabccordion-FAQRenderer';

export const MAGIC_SELECTOR_TARGET = 'magic-selector-target';

const renderers = {
	FAQ: FAQRenderer,
	craftpersons: CraftspeopleRenderer,
};

export const MagicTabccordionAccordionContent = observer((props) => {
	const {
		isLast,
		store,
		store: {
			disableAriaRoles,
			isAccordion,
			isAccordionOrResponsive,
			isResponsive,
			setInitiallyClosed,
			shouldSmoothScroll,
			tabccordionButtonClass,
			tabContentClass,
			tabContentFullHeight,
			tabContentContainerClass,
			tabTitleClass,
			tabTitleTag,
			tabTitleWrapperTag,
			wrapperRenderer,
		},
		tab,
		tab: {
			contentContainerExtraClasses = '',
			tabId = null,
			index,
			responsiveAccordionDefaultClosed,
			tabGroup,
			tabTitleComponent,
			tabTitleElement,
			title,
			trLinkEventCompName = null,
			trLinkEventCompType = null,
			isActive = false,
			isValid = true,
		},
	} = props;
	const content = useRef();
	let animationEndListener = null;
	const tabccordionContainer = useRef();
	const accordionBtn = useRef();
	const [previousSibling, setPreviousSibling] = useState();
	let tabTitle = <span className={tabTitleClass}>{title}</span>; // by default wrap the tab title in a span, unless there is a tabTitleTag attribute
	const TabTitleComponent = tabTitleComponent;
	const ContentRenderer = renderers[wrapperRenderer] || wrapperRenderer;
	const fullTabPanelId = `${tabGroup}-tabccordion-content${index}`;
	const fullTabId = `${tabGroup}-tabccordion-tab${index}`;

	const tabContentClasses = cn(tabContentClass, {
		'content-max-height': !tabContentFullHeight,
		'isActive': isActive,
		'isDisabled': !isValid,
	});

	const contentContainerClasses = cn('Tabccordion-contentContainer', tabContentContainerClass, {
		'isLast': isLast,
		[contentContainerExtraClasses]: Boolean(contentContainerExtraClasses),
	});

	const scrollBehavior = (e) => {
		// if the clickEvent is in the footer, do not scroll to the top of the page (fix for product page WIT-15046)
		const isFooterAccordion = !!e.target.closest('footer');

		if (!shouldSmoothScroll || isFooterAccordion) {
			return;
		}

		const productRow = document.getElementById(MAGIC_SELECTOR_TARGET);

		if (tab.isActive && accordionBtn?.current && previousSibling) {
			setTimeout(function () {
				previousSibling.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
			}, 100);
		} else if (productRow) {
			productRow.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
		}
	};

	if (tabTitleTag) {
		tabTitle = <props.store.tabTitleTag className={tabTitleClass}>{title}</props.store.tabTitleTag>;
	}

	if (tabTitleElement) {
		tabTitle = tabTitleElement;
	}

	useEffect(() => {
		// If the render mode is responsive and the default is set to closed and you are on mobile, mark all accordions as not active
		if (setInitiallyClosed && !responsiveAccordionDefaultClosed) {
			props.tab.isActive = false;
		}
		if (isAccordionOrResponsive && accordionBtn) {
			props.store.addAccordionEventListeners(accordionBtn.current, props.tab);
		}

		if (!isOnServer && accordionBtn) {
			if (!props.store.isLoaded) {
				props.store.setIsLoaded(true);
			}

			animationEndListener = content.current.addEventListener('webkitAnimationEnd', () => {
				props.store.onAnimationEndHandler(accordionBtn.current);
			});
		}

		return () => {
			if (shouldSmoothScroll && content?.current) {
				content.current.removeEventListener('webkitAnimationEnd', animationEndListener);
			}
		};
	}, []);

	useEffect(() => {
		setPreviousSibling(tabccordionContainer?.current ? tabccordionContainer.current.previousElementSibling : null);
	}, [tabccordionContainer.current]);

	return (
		<div
			id={`${tabGroup}-tabccordion-contentContainer${tabId ? `-${tabId}` : `-${index}`}`}
			data-qa={`${tabGroup}-tabccordion-contentContainer${tabId ? `-${tabId}` : `-${index}`}`}
			ref={tabccordionContainer}
			className={contentContainerClasses}
		>
			{(isAccordion || isResponsive) && (
				<>
					{ tabTitleWrapperTag &&
						<props.store.tabTitleWrapperTag>
							<button
								// accessibiltiy specific
								role={disableAriaRoles ? null : 'tab'}
								aria-controls={fullTabPanelId}
								aria-disabled={!isValid} // sets as disabled
								aria-expanded={isActive}
								// other
								id={fullTabId}
								className={cn(['Tabccordion-accordion tw-space-x-2', tabContentClasses, tabccordionButtonClass])}
								suppressHydrationWarning
								disabled={!isValid}
								data-qa={`tabccordion-button-${tabGroup}-${index}`}
								data-tr-link-event-track={false}
								onClick={(e) => {
								// only track if the accordion is not already open and is valid (not disabled)
									if (!tab.isActive && isValid) {
										props.store.trackInteration(tab);
									}
									props.store.activateTab(tab, true);
									scrollBehavior(e);
								}}
								ref={accordionBtn}

							>
								{ Boolean(TabTitleComponent) && <TabTitleComponent tab={tab} tabTitleClass={tabTitleClass} />}
								{ !TabTitleComponent && tabTitle}
								<span className="Tabccordion-status" aria-hidden="true" />
							</button>
						</props.store.tabTitleWrapperTag>
					}
					{ !tabTitleWrapperTag &&
						<button
							// accessibiltiy specific
							role={disableAriaRoles ? null : 'tab'}
							aria-controls={fullTabPanelId}
							aria-disabled={!isValid} // sets as disabled
							aria-expanded={isActive}
							// other
							id={fullTabId}
							className={cn(['Tabccordion-accordion tw-space-x-2', tabContentClasses, tabccordionButtonClass])}
							suppressHydrationWarning
							data-qa={`tabccordion-button-${tabGroup}-${index}`}
							data-tr-link-event-track={false}
							onClick={(e) => {
							// only track if the accordion is not already open and is valid (not disabled)
								if (!tab.isActive && isValid) {
									props.store.trackInteration(tab);
								}
								props.store.activateTab(tab, true);
								scrollBehavior(e);
							}}
							ref={accordionBtn}
						>
							{ Boolean(TabTitleComponent) && <TabTitleComponent tab={tab} tabTitleClass={tabTitleClass} /> }
							{ !TabTitleComponent && tabTitle }
							<span className="Tabccordion-status" aria-hidden="true" />
						</button>
					}
				</>
			)}
			<div
				// accessibiltiy specific
				role={disableAriaRoles ? null : 'tabpanel'}
				aria-labelledby={fullTabId}
				// other
				id={fullTabPanelId}
				suppressHydrationWarning
				className={`Tabccordion-content tabccordion-content${index} ${tabContentClasses}`}
				data-tr-link-event-comp-name={trLinkEventCompName || title}
				data-tr-link-event-comp-type={trLinkEventCompType || 'tabccordion'}
				ref={content}
				data-qa={`tabccordion-${isActive ? 'active' : 'inactive'}-content${index}`}
			>
				<ContentRenderer magicTabccordionStore={store} tab={tab} />
			</div>
		</div>
	);
});
